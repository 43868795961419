// Luxon/ng necessary polyfills
require('./components/polyfills');

window.$ = require('jquery');
window.jQuery = window.$;

require('./blade-components/blade-components');
// included here to use preventHintOverflow() function
// prevent hints from overflowing into the footer
let footer = $('.footer');
let footerDefaultMarginTop = parseInt(footer.css('margin-top'));		// footer margin, before being modified by preventHintOverflow()
function preventHintOverflow() {
	let footerHeight = $(document).height() - footer.offset().top + footerDefaultMarginTop;
	let footerMarginTopDiff = parseInt(footer.css('margin-top')) - footerDefaultMarginTop;
	let allHints = Array.prototype.slice.call(document.querySelectorAll('.hint'));
	let preventedHintOverflow = allHints.some(hint => {
		let hintOffsetBottom = $(document).height() - $(hint).offset().top - $(hint).outerHeight() - footerMarginTopDiff;		// minus footerMarginTopDiff to get the offset bottom when the footer has its default height

		if (hintOffsetBottom < footerHeight) {
			let footerNewMarginTop = footerHeight - hintOffsetBottom;
			footer.css('margin-top', (footerDefaultMarginTop + footerNewMarginTop));
			return true;
		}
	});
	if (!preventedHintOverflow) {
		footer.css('margin-top', footerDefaultMarginTop);
	}
}
let hintOverflowTO = undefined;
window.addEventListener('resize', () => {
	clearTimeout(hintOverflowTO);
	hintOverflowTO = setTimeout(function() { preventHintOverflow(); }, 75);
});
// Initially invoke this, in case step 1 info is long (fileupload for example)
setTimeout(() => {
	preventHintOverflow()
}, 500);

import NP_TM from './components/np-tagmanager';
window.tag = new NP_TM({
	additionalEvents: {
		// Jobgroup
		job0: { event: 'jobgruppe_angestellt' },
		// ja/nein
		jobBtn0_Yes: { event: 'zusatzfrage_angestellt_ja' },
		jobBtn0_No: { event: 'zusatzfrage_angestellt_nein' },
		// modal
		job0ModalPDF: { event: 'popup_angestellt_download_avb' },
		job0ModalPhone: { event: 'popup_angestellt_phone' },
		job0ModalMail: { event: 'popup_angestellt_mail' },
		job0ModalAbort: { event: 'popup_angstellt_abbrechen' },
		job0ModalAccept: { event: 'popup_angstellt_veerstanden' },

		job1: { event: 'jobgruppe_selbstaendig' },
		// ja/nein
		jobBtn1_Yes: { event: 'zusatzfrage_selbstaendig_ja' },
		jobBtn1_No: { event: 'zusatzfrage_selbstaendig_nein' },

		// modal
		job1ModalPDF: { event: 'popup_selbstaendig_download_avb' },
		job1ModalPhone: { event: 'popup_selbstaendig_phone' },
		job1ModalMail: { event: 'popup_selbstaendig_mail' },
		job1ModalAbort: { event: 'popup_selbstaendig_abbrechen' },
		job1ModalAccept: { event: 'popup_selbstaendig_verstanden' },

		job2: { event: 'jobgruppe_beamtet' },
		// modal
		job2ModalPDF: { event: 'popup_beamtet_download_avb' },
		job2ModalPhone: { event: 'popup_beamtet_phone' },
		job2ModalMail: { event: 'popup_beamtet_mail' },
		job2ModalAbort: { event: 'popup_beamtet_abbrechen' },
		job2ModalAccept: { event: 'popup_beamtet_verstanden' },

		job3: { event: 'jobgruppe_andere' },
		// modal
		job3ModalPDF: { event: 'popup_andere_download_avb' },
		job3ModalPhone: { event: 'popup_andere_phone' },
		job3ModalMail: { event: 'popup_andere_mail' },
		job3ModalAbort: { event: 'popup_andere_abbrechen' },
		job3ModalAccept: { event: 'popup_andere_verstanden' },

		// Step 1
		tooltipUnemployment: { event: 'tooltip_arbeitslosigkeit' },
		tooltipDisability: { event: 'tooltip_arbeitsunfaehigkeit' },

		// Step 2
		downloadPDFData: { event: 'download_pdf_datenverarbeitung' },

		// Step 3
		checkboxClickSepa: { event: 'click_checkbox_sepa' },

		// Step 4a
		checkboxClickVVI: { event: 'click_checkbox_vvi' },
		downloadPDFVVI: { event: 'download_pdf_vvi' },

		// Step 4b
		checkboxClickDiscretion: { event: 'click_checkbox_schweigepflicht' },

		// Step 5
		editJob: { event: 'edit_schutz' },
		editPers: { event: 'edit_person_data' },
		editBank: { event: 'edit_bank' },

		// Step 6
		btnBackToStart: { event: 'click_zurueck_zur_startseite' },
		downloadContract: { event: 'download_pdf_vertrag' },

		// navigation
		btnNext1: { event: 'weiter_leistungsauswahl' },
		btnNext2: { event: 'weiter_persoenliche_daten' },
		btnNext3: { event: 'weiter_bankverbindung' },
		btnNext4: { event: 'weiter_rechtliche_hinweise_1_vvi' },
		btnNext5: { event: 'weiter_rechtliche_hinweise_2_schweigepflicht' },
		btnComplete: { event: 'button_abschliessen' },

		btnPrev1: { event: 'zurueck_persoenliche_daten' },
		btnPrev2: { event: 'zurueck_bankverbindung' },
		btnPrev3: { event: 'zurueck_rechtliche_hinweise_1_vvi' },
		btnPrev4: { event: 'zurueck_rechtliche_hinweise_2_schweigepflicht' },
		btnPrev5: { event: 'zurueck_uebersicht' },
	}
});

// Progressbar handling
import ProgressBar from './components/ProgressBar';
if (document.querySelector('.js-progress')) {
	window.Progress = ProgressBar();
}

import Validation from './components/Validation';
window.channel = require('./components/Channel')();

const { DateTime } = require('luxon');
let angular = require('angular');

const STEP1_EKS			= 0;
const STEP2_PERS		= 1;
const STEP3_BANK		= 2;
const STEP4_LEGAL		= 3;
const STEP5_DISCRETION	= 4;
const STEP6_OVERVIEW	= 5;
const STEP7_COMPLETION	= 6;

const mapMonthlyCost = {
	100: '9,90',
	200: '19,90',
	300: '29,90',
	400: '39,90',
	500: '49,90',
	600: '54,90',
};

let csrf_token = document.head.querySelector('meta[name=csrf-token]').getAttribute('content');
let app = angular.module('npSPA', [  ]);
app.controller('AppCtrl', [ '$timeout', '$http', function($timeout, $http) {
	this.tmpl = {
		currentStep: 0,
		hiddenNavigation: true,
		checkingValidity: false,

		fetchingVVI: false,
		vviId: undefined,
		vviError: undefined,

		fetchingContract: false,
		contractId: undefined,
		contractError: undefined,
	};

	this.config = {
		lastVVIFetchData: '',
	};

	this.data = {
		vviDownload: false,
		unbindDiscretion: false,
		jobData: {},
		personalData: {},
		bankData: {},
	};

	this.validation = [
		// validation elements, filled in main()
	];

	/**
	 * Sets the state of ng variables.
	 * Triggers a digest cycle by using $timeout.
	 *
	 * @param	function	func	A function to execute.
	 * @param	integer		delay	Optional delay for when to exec func.
	 */
	this.setState = async(func, delay) => {
		return await $timeout((typeof(delay) !== 'undefined' ? delay : 0)).then(func);
	};

	// Tag Manager Tracking, here and in template
	this.tagmanager = id => {
		tag.push(id);
	};
	this.ga = (id, value) => {
		if (typeof(ga) !== 'undefined') {
			ga('set', id, value);
		}
	};

	/**
	 * Goes a step back.
	 *
	 * @param	integer		step	The new step to go back to, optional.
	 */
	this.back = (step) => {
		let nextStep = typeof(step) !== 'undefined' ? step : this.tmpl.currentStep - 1;

		this.setState(() => {
			// if in template edit button then step is provided as param -> does track another event
			if (typeof(step) === 'undefined') {
				this.tagmanager(`btnPrev${ this.tmpl.currentStep }`);
			}

			this.tmpl.currentStep = nextStep;
			Progress.set(nextStep > 3 ? nextStep-1 : nextStep);

			setTimeout(() => {
				window.scrollTo(0, 0);
				preventHintOverflow();
			}, 100);
		});
	};

	/**
	 * Goes to a specific step or one step further.
	 *
	 * @param	integer		step	The new step to go to, optional.
	 */
	this.next = async (step) => {
		let nextStep = typeof(step) !== 'undefined' ? step : this.tmpl.currentStep + 1;

		await this.setState(() => {
			this.tmpl.checkingValidity = true;
		});

		let delayedResetCheckStatus = () => {
			this.setState(() => this.tmpl.checkingValidity = false, 250);
		};

		let isInvalidInput = () => {
			let foundInvalidValue = false;
			let firstElement = undefined;
			this.validation[this.tmpl.currentStep-1].forEach(el => {
				if (!el.isValidInput()) {
					if (firstElement === undefined) {
						firstElement = el.data.element;
					}
					foundInvalidValue = true;
				}
			});

			if (foundInvalidValue) {
				firstElement.parentNode.scrollIntoView();
				delayedResetCheckStatus();
			}

			return foundInvalidValue;
		};

		// Trigger validation
		switch (this.tmpl.currentStep) {
			case STEP2_PERS:
				if (isInvalidInput()) { return; }

				channel.notify('fetchJobData', {});  // fetch data, if we reach step 3 we know this data was complete
			break;

			case STEP3_BANK:
				if (isInvalidInput()) { return; }

				channel.notify('fetchPersonalData', {});
				let result = await this.fetchVVI();  // we delay for a single step, or STEP3_BANK is blocked until VVI is finished
				if (result !== true) {  // if an error occured, abort
					return;
				}
			break;

			case STEP4_LEGAL:
				if (isInvalidInput()) { return; }

				channel.notify('fetchBankData', {});
			break;

			case STEP5_DISCRETION:
				if (isInvalidInput()) { return; }
			break;

			case STEP6_OVERVIEW:
				// overview has nothing to validate
				await this.fetchContract();
			break;
		}

		this.setState(() => {
			this.tmpl.currentStep = nextStep;
			try {
				Progress.set(nextStep > 3 ? nextStep-1 : nextStep);
			} catch (e) {
				// Progess.set throws if a non existing step was requested
			}
			this.tmpl.checkingValidity = false;

			setTimeout(() => {
				window.scrollTo(0, 0);
				preventHintOverflow();
			}, 100);

			if (nextStep == STEP7_COMPLETION) {
				this.tagmanager('btnComplete');
			} else {
				this.tagmanager(`btnNext${ nextStep }`);
			}
		});
	};

	this.fetchVVI = async () => {
		// object destructuring, https://stackoverflow.com/a/51340842 combined with https://stackoverflow.com/a/57568054
		// we only send what we need to send, so we can check if we need to re-send if user clicks back and forth
		let filteredData = (({ personalData: { firstName, lastName }, jobData: { jobGroup, partTime, smallCompany } }) => ({ personalData: { firstName, lastName }, jobData: { jobGroup, partTime, smallCompany } }))(this.data);
		// if nothing changed that is used for VVI we won't need to resend, as we did that already and have the id for DL
		let stringified = JSON.stringify(filteredData);
		if (stringified === this.config.lastVVIFetchData) {
			return true;
		}

		await this.setState(() => {
			this.tmpl.fetchingVVI = true;
		});
		let result = await $http({
				method: 'POST',
				url: ROUTES.vvi,
				data: filteredData,
				headers: {
					'X-CSRF-TOKEN': csrf_token
				}
			}).then(resp => {
				if (!resp.data.error) {
					this.setState(() => {
						this.tmpl.vviId = resp.data.data.vviId;
						this.tmpl.vviError = undefined;
						this.tmpl.fetchingVVI = false;

						this.config.lastVVIFetchData = stringified;
					});
				} else {
					this.setState(() => {
						this.tmpl.vviError = resp.data.message;
						this.tmpl.fetchingVVI = false;

						this.config.lastVVIFetchData = '';
					});
				}

				return true;
			}).catch(resp => {
				this.setState(() => {
					this.tmpl.vviId = undefined;  // reset any previous id
					this.tmpl.vviError = resp.data.message;
					this.tmpl.fetchingVVI = false;

					this.config.lastVVIFetchData = '';
				});

				return false;
			});

		return result;
	};
	/**
	 * Sending the collected data to the server to create a contract.
	 */
	this.fetchContract = async () => {
		await this.setState(() => this.tmpl.fetchingContract = true);

		await $http({
			method: 'POST',
			url: ROUTES.contract,
			data: this.data,
			headers: {
				'X-CSRF-TOKEN': csrf_token
			}
		}).then(resp => {
			if (!resp.data.error) {
				this.setState(() => {
					this.tmpl.contractId = resp.data.data.contractId;
					this.tmpl.contractError = undefined;

					this.tmpl.fetchingContract = false
				});

				// #CLIKANO-130, metrics are supposed to be send as strings
				this.ga('metric1', this.data.jobData.insuranceAmountInput + '');
			} else {
				this.setState(() => {
					this.tmpl.contractError = resp.data.message;
					this.tmpl.fetchingContract = false;
				});

			}
		}).catch(resp => {
			this.setState(() => {
				this.tmpl.contractError = resp.data.message;
				this.tmpl.fetchingContract = false;
			});
		});
	};

	this.downloadVVI = () => {
		let a = document.createElement('a');
			a.setAttribute('href', ROUTES.dlVVI.replace('#vviid#', this.tmpl.vviId));
			a.setAttribute('target', '_blank');
			a.setAttribute('rel', 'noopener noreferrer');
			a.setAttribute('class', 'hidden');
		document.body.appendChild(a);
		a.click();
		a.remove();

		this.tagmanager('downloadPDFVVI');
	};

	this.downloadContract = () => {
		let a = document.createElement('a');
			a.setAttribute('href', ROUTES.dlContract.replace('#contractid#', this.tmpl.contractId));
			a.setAttribute('target', '_blank');
			a.setAttribute('rel', 'noopener noreferrer');
			a.setAttribute('class', 'hidden');
		document.body.appendChild(a);
		a.click();
		a.remove();

		this.tagmanager('downloadContract');
	};

	this.summary = {
		hideUnemploymentInsurance: () => {
			try {
				return this.data.jobData.partTimeModalAccepted || this.data.jobData.smallCompanyModalAccepted || this.data.jobData.clerkModalAccepted || this.data.jobData.otherJobGroupModalAccepted;
			} catch (e) {
				return false;
			}
		},
		monthlyCost: () => {
			try {
				return mapMonthlyCost[this.data.jobData.insuranceAmountRange];
			} catch (e) {
				return false;
			}
		},
		formattedIban: () => {
			try {
				let iban = this.data.bankData.iban;
				return iban.replace(/ /g, '').match(/.{2,4}/g).join(' ');
			} catch (e) {
				return '';
			}
		}
	}

	this.main = () => {
		// Step 1: Hiding/Displaying the Navigation buttons
		channel.register('toggleNavigation', data => {
			this.setState(() => {
				this.tmpl.hiddenNavigation = data.hide;
			});
		});

		// Validation: Displaying the correct error messages
		// if any of these rules return true the error message must be displayed
		let errorRules = {
			empty:			v => !v.length,
			len5:			v => v.length != 5,
			len22:			v => v.length != 22,
			mail:			v => v.length != 0 && !v.match(/^[a-z0-9_\\-\\.]+@[a-z0-9_\\-\\.]+\\.[a-z0-9]+$/i),
			ibanFormat:		v => !v.replace(/ /g, '').match(/^\D{2}\d{20}$/i),
			ibanInvalid:	v => document.querySelector('#input--text-iban').getAttribute('valid-iban') !== 'true',
			checked:		v => v != true,
			birthDayEmpty:	v => v === '..',
			age18:			v => {
				let age = -1*DateTime.fromFormat(`${ v } 00:00:00`, 'dd.MM.yyyy HH:mm:ss').diffNow('years').years;
				return Number.isNaN(age) || age < 18;
			},
			age65:			v => {
				let age = -1*DateTime.fromFormat(`${ v } 00:00:00`, 'dd.MM.yyyy HH:mm:ss').diffNow('years').years;
				return Number.isNaN(age) || age > 65;
			},
			salutation:		v => {
				return v !== 'm' && v !== 'f';
			},
		};
		// This is the connection between errors and (validaton) rules
		// the index of the error span in the template defines the accompanying rule in this map
		let mapFormElements = {
			'default':						[ 'empty' ],  // currently only one possible error, fallback error message
			'input--text-zip':				[ 'empty', 'len5' ],
			'input--text-mail':				[ 'mail' ],
			'input--text-iban':				[ 'ibanFormat', 'ibanInvalid' ],
			'checkbox-1-sepa':				[ 'checked' ],
			'checkbox-1-document-download': [ 'checked' ],
			'checkbox-1-confidentiality':	[ 'checked' ],
			'input--text-birthday-date':	[ 'birthDayEmpty', 'age18', 'age65' ],
			'input--select-salutation':		[ 'salutation' ]
		};

		// Add custom rules, we need to create them before we instantiate _all_ validations
		let hiddenSalutationInput = document.querySelector('#input--text-hidden-salutation');
		Validation.prototype.addRule('salutation', function(input) {
			let val = !!hiddenSalutationInput ? hiddenSalutationInput.value : undefined;

			return val == 'm' || val == 'f';
		});
		Validation.prototype.addRule('birthday', function(input) {
			if (input == '..') { return false; }

			try {
				// years will be negative
				let age = -1*DateTime.fromFormat(`${ input } 00:00:00`, 'dd.MM.yyyy HH:mm:ss').diffNow('years').years;

				if (Number.isNaN(age) || age < 18 || age > 65) {
					return false;
				}
			} catch (e) {
				return false;
			}

			return true;
		});
		let ibanInput = document.querySelector('#input--text-iban');
		Validation.prototype.addRule('customIban', function(input) {
			if (!input.replace(/ /g, '').match(/^\D{2}\d{20}$/i)) { return false; }
			if(ibanInput.getAttribute('checking-iban') === 'true') { return false; }  // api check ongoing

			let prop = ibanInput.getAttribute('valid-iban');
			if (!prop || prop !== 'true') {
				return false;
			}

			return true;
		});

		// Extending Validation instance for our hint/error handling
		let initFunc = that => {
			// Fetch parent container and look for the hint, if this container isn't available and null
			// we encountered an edge case to fix
			let parent = that.data.element.closest('.input__container');

			let hintInitial = parent.querySelector('.hint.hint--view-option-initial');
			// if the input is invalid, we need to display the error message
			// but not the hint while focus
			that.data.element.addEventListener('validationResult', e => {
				// Figure out what error message must be displayed
				if (!e.detail.isValid && that.config.errors.length > 0) {
					if (hintInitial) {
						hintInitial.classList.add('hidden');
					}
					let id = that.data.element.getAttribute('id');

					// This element does not have its own rule, so use 'default'
					if (typeof(mapFormElements[id]) === 'undefined') {
						if (that.config.errors.length) {
							that.config.lastDisplayedError = 0;
						}
					} else {
						mapFormElements[id].some((key, i) => {
							if (errorRules[key](e.detail.currentValue)) {
								that.config.lastDisplayedError = i;

								return true;
							}
							return false;
						});
					}

					// birthday has a proxy input
					if (that.data.element.getAttribute('id') === 'input--text-birthday-date') {
						// we need to hide potentially previously displayed errors, as we have no blur event here
						that.config.errors.forEach(err => err.classList.add('hidden'));
						that.config.errors[that.config.lastDisplayedError].classList.remove('hidden');

						that.data.element.closest('.js-compound-container').classList.add('invalid-input');
					} else if (that.data.element.getAttribute('id') === 'input--select-salutation') {
						that.config.errors.forEach(err => err.classList.add('hidden'));
						that.config.errors[that.config.lastDisplayedError].classList.remove('hidden');
					}

					// Display errors for non-blur/focusable form elements
					switch(that.data.element.nodeName.toLowerCase()) {
						case 'input':
							let type = that.data.element.getAttribute('type');
							if (type === 'radio' || type === 'checkbox') {
								that.config.errors.forEach(err => err.classList.add('hidden'));
								that.config.errors[that.config.lastDisplayedError].classList.remove('hidden');
							}
						break;

						case 'select':
							that.config.errors.forEach(err => err.classList.add('hidden'));
							that.config.errors[that.config.lastDisplayedError].classList.remove('hidden');
						break;

						default: break;
					}
				// valid input, hide previously displayed error message
				} else if (e.detail.isValid && that.config.lastDisplayedError >= 0) {
					if (hintInitial) {
						hintInitial.classList.remove('hidden');
					}

					// leave this here, since animationstart will trigger a validationResult
					that.config.errors[that.config.lastDisplayedError].classList.add('hidden');
					that.config.lastDisplayedError = -1;

					if (that.data.element.getAttribute('id') === 'input--text-birthday-date') {
						that.data.element.closest('.js-compound-container').classList.remove('invalid-input');
					}
				}
			});

			// Remember the available errors, can be empty
			that.config.errors = Array.prototype.slice.call(
				parent.querySelectorAll('.input__error-container .error')
			);
			that.config.lastDisplayedError = -1;
			let hintOnFocus = parent.querySelector('.hint.hint--view-option-focus');

			if (that.data.element.nodeName.toLowerCase() !== 'select') {
				if (that.data.element.nodeName.toLowerCase() !== 'input' || that.data.element.getAttribute('type') !== 'checkbox') {
					// Display hint that is to be displayed on focus, unless error is displayed
					that.data.element.addEventListener('focus', e => {
						if (!!hintOnFocus && that.config.lastDisplayedError == -1) {  // we only need to check on focus, blur doesn't matter
							hintOnFocus.classList.remove('hint--view-option-focus');
						} else if (that.config.lastDisplayedError > -1) {
							that.config.errors[that.config.lastDisplayedError].classList.remove('hidden');
						}
					});
					that.data.element.addEventListener('blur', e => {
						if (!!hintOnFocus) {
							hintOnFocus.classList.add('hint--view-option-focus');
						}

						// if an input has multiple error messages,
						// lastDisplayedError will be changed sometime between blurStart and blurEnd, this might end up being a racecondition
						// hence simply hide any err
						that.config.errors.forEach(err => err.classList.add('hidden'));
					});
				} else {
					that.data.element.addEventListener('click', e => {
						if (!!hintOnFocus && that.config.lastDisplayedError == -1) {  // we only need to check on focus, blur doesn't matter
							hintOnFocus.classList.remove('hint--view-option-focus');
						} else if (that.config.lastDisplayedError > -1) {
							that.config.errors[that.config.lastDisplayedError].classList.remove('hidden');
						}
					});
				}
			} else {
				that.data.element.addEventListener('click', e => {
					if (!!hintOnFocus && that.config.lastDisplayedError == -1) {  // we only need to check on focus, blur doesn't matter
						hintOnFocus.classList.remove('hint--view-option-focus');
					} else if (that.config.lastDisplayedError > -1) {
						that.config.errors[that.config.lastDisplayedError].classList.remove('hidden');
					}
				});
			}
		};

		// Initialize Validation
		let validations = Array.prototype.slice.call(document.querySelectorAll('[validate]'));
			validations.forEach(el => new Validation(el, initFunc));

		// mapping elements to steps, saving the validation instance
		this.validation = [
			[
				'#input--select-salutation', '#input--text-firstname', '#input--text-lastname',
				'#input--text-street', '#input--text-zip', '#input--text-city',
				'#input--text-birthday-date', '#input--text-mail'
			].reduce((prevVal, curVal) => {
				let el = document.querySelector(curVal);
				let vI = new Validation(el);  // returns the existing instance
				prevVal.push(vI);
				return prevVal;
			}, []),

			[
				'#input--text-iban', '#checkbox-1-sepa'
			].reduce((prevVal, curVal) => {
				let el = document.querySelector(curVal);
				let vI = new Validation(el);
				prevVal.push(vI);
				return prevVal;
			}, []),

			[
				'#checkbox-1-document-download'
			].reduce((prevVal, curVal) => {
				let el = document.querySelector(curVal);
				let vI = new Validation(el);
				prevVal.push(vI);
				return prevVal;
			}, []),

			[
				'#checkbox-1-confidentiality'
			].reduce((prevVal, curVal) => {
				let el = document.querySelector(curVal);
				let vI = new Validation(el);
				prevVal.push(vI);
				return prevVal;
			}, [])
		];

		channel.register('sendJobData', data => {
			this.data.jobData = data;
		});
		channel.register('sendPersonalData', data => {
			this.data.personalData = data;
		});
		channel.register('sendBankData', data => {
			this.data.bankData = data;
		});
	};
	this.main();
}]);

app.controller('JobCtrl', [ '$timeout', function($timeout) {
	this.config = {
		modalTriggerPartTime: undefined,
		modalTriggerSmallCompany: undefined,
		modalTriggerClerk: undefined,
		modalTriggerOtherJobGroup: undefined,
		lastValuePartTime: undefined,
		lastValueSmallCompany: undefined,
	};

	this.data = {
		jobGroup: undefined,			// 0 Angestellt, 1 Selbstständig, 2 Beamtet, 3 Other
		partTime: undefined,			// Employed as jobgroup, not fulltime working ("nein" Button in FE) clicked then this is 1
		partTimeModalAccepted: false,	// The modals ask for "Yes", we track it here to unhide the insurance sum
		smallCompany: undefined,		// 1 Selbstständig as jobgroup, Kleinunternehmer, 1 if nein is clicked
		smallCompanyModalAccepted: false,
		clerkModalAccepted: undefined,
		otherJobGroupModalAccepted: undefined,

		insuranceAmountRange: 300,  // the range slider, stickied at steps of 100
		insuranceAmountInput: 300,	// manual input
	};

	this.setState = async(func) => {
		return await $timeout(0).then(func);
	};

	this.tmpl = {
		setPartTime: ($event) => {
			if (this.config.lastValuePartTime == $event.currentTarget.value) {
				return;
			}
			// We can't directly compare this.data.partTime with currentTarget.value,
			// bc in IE the ng-bind triggers and updates before this function call,
			// while in other browsers it's after this func
			this.config.lastValuePartTime = $event.currentTarget.value;

			this.setState(() => {
				this.data.partTime = $event.currentTarget.value;

				// display navigation if no was selected, on yes a modal is opened
				if (this.data.partTime == 0) {
					tag.push(`jobBtn0_No`);

					this.data.partTimeModalAccepted = false;  // reset
					channel.notify('toggleNavigation', { hide: false });
				} else {
					tag.push(`jobBtn0_Yes`);

					channel.notify('toggleNavigation', { hide: true });
					this.config.modalTriggerPartTime.click();
				}
			});
		},
		setSmallCompany: ($event) => {
			if (this.config.lastValueSmallCompany == $event.currentTarget.value) {
				return;
			}
			this.config.lastValueSmallCompany = $event.currentTarget.value;

			this.setState(() => {
				this.data.smallCompany = $event.currentTarget.value;

				// display navigation if no was selected, on yes a modal is opened
				if (this.data.smallCompany == 0) {
					tag.push(`jobBtn1_No`);

					this.data.smallCompanyModalAccepted = false;  // reset
					channel.notify('toggleNavigation', { hide: false });
				} else {
					tag.push(`jobBtn1_Yes`);

					channel.notify('toggleNavigation', { hide: true });
					this.config.modalTriggerSmallCompany.click();
				}
			});
		},
		hideInsurance: () => {
			switch (parseInt(this.data.jobGroup, 10)) {
				default:
					return true;

				case 0:
					return this.data.partTime == undefined || this.data.partTime == 1 && !this.data.partTimeModalAccepted;

				case 1:
					return this.data.smallCompany == undefined || this.data.smallCompany == 1 && !this.data.smallCompanyModalAccepted;

				case 2:
					return !this.data.clerkModalAccepted;

				case 3:
					return !this.data.otherJobGroupModalAccepted;
			}
		},
		hideUnemploymentInsurance: () => {
			return this.data.partTimeModalAccepted || this.data.smallCompanyModalAccepted || this.data.clerkModalAccepted || this.data.otherJobGroupModalAccepted;
		},
		monthlyCost: () => {
			return mapMonthlyCost[this.data.insuranceAmountRange];
		},
	};

	this.main = () => {
		let jobGroup = document.querySelector('.js-sel-jobGroup select');
		if (jobGroup) {
			jobGroup.addEventListener('change', select => {
				let optValue = select.target.value || select.currentTarget.value;
				tag.push(`job${ optValue }`);

				this.setState(() => {
					// reset
					this.data.partTime = undefined;
					this.data.partTimeModalAccepted = false;
					this.data.smallCompany = undefined;
					this.data.smallCompanyModalAccepted = false;
					this.data.clerkModalAccepted = false;
					this.data.otherJobGroupModalAccepted = false;

					this.data.jobGroup = optValue;
					channel.notify('toggleNavigation', { hide: true });

					switch (parseInt(optValue, 10)) {
						default: break;
						case 2:
							this.config.modalTriggerClerk.click();
						break;
						case 3:
							this.config.modalTriggerOtherJobGroup.click();
						break;
					}
				});
			});
		}

		let abortModal = Array.prototype.slice.call(document.querySelectorAll('.js-abortModal'));
		if (abortModal) {
			abortModal.forEach(btn => {
				btn.addEventListener('click', e => {
					tag.push(`job${ this.data.jobGroup }ModalAbort`);
				})
			});
		}

		// Setup for modal triggers
		this.config.modalTriggerPartTime = document.querySelector('.js-modal-step1-parttime');
		let modalPartTimeAccept = document.querySelector('.js-modal-pt');
		modalPartTimeAccept.addEventListener('click', () => {
			this.setState(() => {
				this.data.partTimeModalAccepted = true;
				channel.notify('toggleNavigation', { hide: false });

				tag.push(`job0ModalAccept`);
			});
		});

		this.config.modalTriggerSmallCompany = document.querySelector('.js-modal-step1-smallcompany');
		let modalSmallCompanyAccept = document.querySelector('.js-modal-sc');
		modalSmallCompanyAccept.addEventListener('click', () => {
			this.setState(() => {
				this.data.smallCompanyModalAccepted = true;
				channel.notify('toggleNavigation', { hide: false });

				tag.push(`job1ModalAccept`);
			});
		});

		this.config.modalTriggerClerk = document.querySelector('.js-modal-step1-clerk');
		let modalClerkAccept = document.querySelector('.js-modal-ck');
		modalClerkAccept.addEventListener('click', () => {
			this.setState(() => {
				this.data.clerkModalAccepted = true;
				channel.notify('toggleNavigation', { hide: false });

				tag.push(`job2ModalAccept`);
			});
		});

		this.config.modalTriggerOtherJobGroup = document.querySelector('.js-modal-step1-other-jobgroup');
		let modalOtherJobGroupAccept = document.querySelector('.js-modal-ojg');
		modalOtherJobGroupAccept.addEventListener('click', () => {
			this.setState(() => {
				this.data.otherJobGroupModalAccepted = true;
				channel.notify('toggleNavigation', { hide: false });

				tag.push(`job3ModalAccept`);
			});
		});

		// Range input handling
		let rangeInput = document.querySelector('#rangeinput-insurance-amount');
		let rangeInputBubble = rangeInput.parentNode.querySelector('.js-bubble');

		let am = document.querySelector('#smallnumberinput-insurance-amount');
		// Initial value, needs to be checked for valid input
		if (am.getAttribute('ng-init')) {
			this.setState(() => {
				let val = parseInt(am.getAttribute('ng-init'), 10);
				if (val < 100 || val > 600) {
					this.data.insuranceAmountInput = 300;
					this.data.insuranceAmountRange = 300;
				} else {
					this.data.insuranceAmountInput = val;
					this.data.insuranceAmountRange = val;
				}

				setTimeout(() => setBubble(rangeInput, rangeInputBubble), 25);
			});
		}

		// Link number input to range value
		am.addEventListener('keyup', e => {
			if (am.value.length < 3) { return; }
			let value = parseInt(am.value);
			if (value < 100) {
				value = 100;
			} else if (value > 600) {
				value = 600;
			}

			let rounded = Math.round(value/100) * 100;
			if (parseInt(am.value, 10) != rounded) {
				am.value = rounded;
			}
			if (rounded != this.data.insuranceAmountRange) {  // avoid unnecessary update of angular bind
				this.setState(() => {
					this.data.insuranceAmountRange = rounded;

					setTimeout(() => setBubble(rangeInput, rangeInputBubble), 25);
				});
			}
		});

		// Blur rounds the value
		am.addEventListener('blur', async (e) => {
			// first make sure, in valid range
			let value = parseInt(am.value);
			if (value < 100) {
				value = 100;
			} else if (value > 600) {
				value = 600;
			}

			let rounded = Math.round(value/100) * 100;
			if (parseInt(am.value, 10) != rounded) {
				am.value = rounded;
			}
			if (rounded != this.data.insuranceAmountRange) {  // avoid unnecessary update of angular bind
				await this.setState(() => this.data.insuranceAmountRange = rounded);

				setTimeout(() => setBubble(rangeInput, rangeInputBubble), 25);

			}
		});

		let funcRangeChange = async () => {
			let parsed = parseInt(rangeInput.value, 10);
			if (am.value != parsed) {
				am.value = parsed;

				await this.setState(() => this.data.insuranceAmountInput = parsed);
				setBubble(rangeInput, rangeInputBubble);
			}
		};
		// doesn't trigger on mobile
		rangeInput.addEventListener('input', e => {
			funcRangeChange();
		});
		// does trigger on mobile and on mouse release
		rangeInput.addEventListener('change', e => {
			funcRangeChange();
		});

		channel.register('fetchJobData', data => {
			channel.notify('sendJobData', this.data);
		});
	};
	this.main();
}]);

app.controller('PersonalCtrl', [ '$timeout', '$http', '$q', function($timeout, $http, $q) {
	this.data = {
		salutation: undefined,
		firstName: undefined,
		lastName: undefined,
		street: undefined,
		zip: undefined,
		city: undefined,
		mail: undefined,
		birthDay: undefined,
	};

	this.config = {
		autoCompleteQ: undefined,
		lastZipCheckSearch: undefined,
		lastZipCheckResponse: undefined,
		cityInputValidation: undefined,
	};

	this.setState = async(func) => {
		return await $timeout(0).then(func);
	};

	this.tmpl = {
		pollZipCode: () => {
			if (this.data.zip === undefined || this.data.zip.length != 5) { return; }

			if (this.config.lastZipCheckResponse !== undefined && this.config.lastZipCheckResponse.length === 1
				&& this.config.lastZipCheckSearch === this.data.zip) {
				this.setState(() => {
					this.data.city = this.config.lastZipCheckResponse[0][1];

					setTimeout(() => this.config.cityInputValidation.isValidInput(), 75);
				});

				return;
			}

			this.config.autoCompleteQ = $q.defer();
			$http({
				timeout: this.config.autoCompleteQ.promise,
				method: 'GET',
				url: ROUTES.zip.replace('#zip#', this.data.zip),
				headers: {
					'X-CSRF-TOKEN': csrf_token
				}
			}).then(resp => {
				this.config.lastZipCheckSearch = this.data.zip;

				if (!resp.data.error) {
					let oldCheck = this.config.lastZipCheckResponse != undefined ? this.config.lastZipCheckResponse[0][1] : undefined;
					if (this.data.city === undefined || this.data.city.length === 0 || oldCheck === this.data.city) {
						this.setState(() => {
							this.data.city = resp.data.cities[0][1];
							setTimeout(() => this.config.cityInputValidation.isValidInput(), 75);
						});
					}

					this.config.lastZipCheckResponse = resp.data.cities;

					return;
				}
			}).catch(resp => {
				// can be empty
			});
		}
	}

	this.main = () => {
		// we fetch the instance here, the appCtrl should have created the instance already
		this.config.cityInputValidation = document.querySelector('#input--text-city');
		this.config.cityInputValidation = new Validation(this.config.cityInputValidation);

		// Setup the birthday stuff
		try {
			let hiddenBirthdayInput = document.querySelector('#input--text-birthday-date');
				hiddenBirthdayInput.value = '..';
			[
				document.querySelector('#input--select-birthday-day'),
				document.querySelector('#input--select-birthday-month'),
				document.querySelector('#input--select-birthday-year')
			].forEach((el, i) => {
				el.addEventListener('change', select => {
					let optValue = select.target.value || select.currentTarget.value;

					let inp = hiddenBirthdayInput.value.split('.');
						inp[i] = optValue < 10 ? `0${ optValue }` : optValue;
					hiddenBirthdayInput.value = inp.join('.');
					if (hiddenBirthdayInput.value.match(/\d{2}\.\d{2}\.\d{4}/i)) { // trigger a validation if all 3 selects were touched
						window.dispatchEvent(new CustomEvent('e_birthday'));
					}

					this.data.birthDay = hiddenBirthdayInput.value;
				});
			});

			let salutation = document.querySelector('.js-sel-salutation select');
			let hiddenSalutationInput = document.querySelector('#input--text-hidden-salutation');
			if (salutation) {
				salutation.addEventListener('change', async (select) => {
					let optValue = select.target.value || select.currentTarget.value;

					await this.setState(() => {
						this.data.salutation = optValue;
						hiddenSalutationInput.value = optValue;
					});

					window.dispatchEvent(new CustomEvent('e_salutation'));
				});
			}
		} catch(e) {
			console.error(e);
		}

		channel.register('fetchPersonalData', data => {
			channel.notify('sendPersonalData', this.data);
		});
	};
	this.main();
}]);

app.controller('BankCtrl', [ '$timeout', '$http', '$q', function($timeout, $http, $q) {
	this.config = {
		qDefer: undefined,
		lastChecked: undefined,
		lastCheckValid: false,
		ibanInput: undefined,
	};
	this.data = {
		iban: undefined,
		bic: undefined,
		bank: undefined,
		sepa: undefined,
	};

	this.setState = async(func) => {
		return await $timeout(0).then(func);
	};

	this.tmpl = {
		pollIban: () => {
			if (this.data.iban === undefined || this.data.iban.length != 22) {
				return;
			}

			this.config.ibanInput.setAttribute('checking-iban', 'true');
			if (this.config.lastCheckValid && this.data.iban === this.config.lastChecked.iban) {
				this.setState(() => {
					this.data.bank = this.config.lastChecked.bank;
					this.data.bic = this.config.lastChecked.bic;

					this.config.ibanInput.setAttribute('checking-iban', 'false');
					setTimeout(() => window.dispatchEvent(new CustomEvent('e_iban'), 75));
				});

				return;
			}

			this.config.qDefer = $q.defer();
			$http({
				timeout: this.config.qDefer,
				method: 'GET',
				url: ROUTES.checkIban.replace('#iban#', this.data.iban),
				headers: {
					'X-CSRF-TOKEN': csrf_token
				}
			}).then(resp => {
				if (!resp.data.error) {
					this.setState(() => {
						this.data.bank = resp.data.data.bank;
						this.data.bic = resp.data.data.bic;

						this.config.lastCheckValid = true;
						this.config.lastChecked = { iban: this.data.iban, ...resp.data.data };

						this.config.ibanInput.setAttribute('valid-iban', 'true');

						this.config.ibanInput.setAttribute('checking-iban', 'false');
						setTimeout(() => window.dispatchEvent(new CustomEvent('e_iban'), 75));
					});
				} else {
					this.setState(() => {
						this.data.bank = undefined;
						this.data.bic = undefined;

						this.config.lastCheckValid = false;
						this.config.lastChecked = { iban: this.data.iban };

						this.config.ibanInput.setAttribute('valid-iban', 'false');

						this.config.ibanInput.setAttribute('checking-iban', 'false');
						setTimeout(() => window.dispatchEvent(new CustomEvent('e_iban'), 75));
					});
				}
			}).catch(resp => {
				this.setState(() => {
					this.data.bank = undefined;
					this.data.bic = undefined;

					this.config.lastCheckValid = false;
					this.config.lastChecked = { iban: this.data.iban };

					this.config.ibanInput.setAttribute('valid-iban', 'false');

					this.config.ibanInput.setAttribute('checking-iban', 'false');
					setTimeout(() => window.dispatchEvent(new CustomEvent('e_iban'), 75));
				});
			});
		}
	};

	this.main = () => {
		channel.register('fetchBankData', data => {
			channel.notify('sendBankData', this.data);
		});

		this.config.ibanInput = document.querySelector('#input--text-iban');
	};
	this.main();
}]);
