/**
 * Singleton pattern. Only one progressbar is allowed.
 */
let ProgressInstance = (function () {
    let instance;

	/**
	 * Creating the instance.
	 *
	 * @param	string	selectorContainer	The Progress HTMLElement ol-container selector.
	 * @param	string	stepSelector		Selector for step HTMLElements.
	 * @param	integer	initStep 			Initial active step, zero based.
	 */
    function createInstance(selectorContainer, stepSelector, initStep) {
        let ProgressInstance = function(selectorContainer, stepSelector, initStep) {
			this.currentStep = 0;
			this.container = document.querySelector(selectorContainer);
			this.steps = Array.prototype.slice.call(
				document.querySelectorAll(stepSelector)
			);

			if (!this.container) {
				throw('No container available!');
			}

			this.set(initStep);
		};

		/**
		 * Sets progress. If step is bigger than the available steps, or less than 0,
		 * the min/max is determined and used instead.
		 *
		 * @param	integer		step	The new progress to display.
		 */
		ProgressInstance.prototype.set = function(step) {
			// determin min/max steps to set
			let newStep = Math.min(this.steps.length, step);
				newStep = Math.max(0, newStep);

			if (typeof(this.steps[newStep]) !== 'undefined') {
				this.steps[this.currentStep].classList.remove('active');
				this.steps[newStep].classList.add('active');

				this.currentStep = newStep;
			} else {
				throw('Invalid progress requested!');
			}
		};

		/**
		 * Checks visibility for a step.
		 *
		 * @param	integer		step	The step to check for visibility.
		 * @return	boolean				true = visible, false = hidden
		 */
		ProgressInstance.prototype.isVisible = function(step) {
			return this.steps[step].classList.contains('hidden');
		}

		/**
		 * Adds or removes the class hidden from a step.
		 *
		 * @param	integer		step	The step to change.
		 * @param	boolean		visible	The new visibility state, if true: removes hidden, if false: adds hidden class.
		 */
		ProgressInstance.prototype.setVisibility = function(step, visible) {
			if (typeof(this.steps[step]) !== 'undefined') {
				this.steps[step].classList[ ([ 'add', 'remove' ][+visible]) ]('hidden');
			}
		};

		/**
		 * @param	string		_class	The class to add or remove.
		 * @param	boolean		remove	if provided removes the class.
		 */
		ProgressInstance.prototype.setClass = function(_class, remove) {
			this.container.classList[ ([ 'add', 'remove' ][ (typeof(remove) === 'undefined') ? 0 : 1 ]) ](_class);
		};

		/**
		 * Returns the current step.
		 *
		 * @returns		The current active step, zero based.
		 */
		ProgressInstance.prototype.get = function() {
			return this.currentStep;
		};

        return new ProgressInstance(selectorContainer, stepSelector, initStep);
    }

    return {
        getInstance: function(selectorContainer, stepSelector, initStep) {
			if (!instance && !selectorContainer) {
				throw('Cannot create instance without selector!');
			}
            if (!instance) {
                instance = createInstance(selectorContainer, stepSelector, initStep);
			}
            return instance;
        }
    };
})();


/**
 * ProgressBar object. Implemented as a Singleton object, since only a single progress are is available.
 * Uses pre-defined selectors that can be overwritten in the JS where it's initiated.
 *
 * @param	string	selectorContainer	The Progress HTMLElement ol-container selector.
 * @param	string	stepSelector		Selector for step HTMLElements.
 * @param	integer	initStep 			Initial active step, zero based.
 */
export default function ProgressBar(selectorContainer = '.js-progress', stepSelector = '.js-step', initStep = 0) {
	if (!(this instanceof ProgressBar)) {
        return new ProgressBar(selectorContainer, stepSelector, initStep);
	}

    return ProgressInstance.getInstance(selectorContainer, stepSelector, initStep);
}
