// Custom Event Polyfill IE
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent#Polyfill
(function () {
	if ( typeof window.CustomEvent === "function" ) return false;
  
	function CustomEvent ( event, params ) {
	  params = params || { bubbles: false, cancelable: false, detail: null };
	  var evt = document.createEvent( 'CustomEvent' );
	  evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
	  return evt;
	 }
  
	window.CustomEvent = CustomEvent;
})();

// https://developer.mozilla.org/en-US/docs/Web/API/Element/closest
if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || 
								Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;

		do {
			if (Element.prototype.matches.call(el, s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);

		return null;
	};
}

const Rules = {
	phone: function(input) {
		return input !== null && input.match(/^([\d ]+?)$/);
	},
	mail: new RegExp("^[a-z0-9_\\-\\.]+@[a-z0-9_\\-\\.]+\\.[a-z0-9]+$", "i"),
	nonEmpty: function(input) {
		return input !== null && input.length > 0;
	},
	nonEmptyAlpha: function(input) {
		return input !== null && input.length > 0 && !input.match(/\d/g);
	},
	nonZero: function(input) {
		return parseFloat( input.replace(/\.[a-zA-Züöäß€]/g,'').replace(/,/, '.') ) > 0;
	},
	zipCode: function(input) {
		return input !== null && input.length === 5 && input.match(/^\d{5}$/i);
	},
	iban: function(v) {
		return v.replace(/ /g, '').match(/^\D{2}\d{20}$/i);
	},
	checked: v => {
		return v == 1 || v === true;
	},
};

/**
 * Singleton, "private" List of Validation instances.
 * Private, because it's not exported and not accessible that way,
 * other than inside Validation.
 * 
 * Provides methods to Validation to get elements by uuid that is visible on the element.
 */
let ListValidation = (function () {
    let instance;
 
    function createInstance() {
        let ListValidation = function() {
			this.elements = {};
		};

		ListValidation.prototype.push = function(id, element) {
			if (typeof(this.elements[id]) !== 'undefined') {
				throw('Element exists already');
			}

			this.elements[id] = element;
		};

		ListValidation.prototype.pop = function(id) {
			if (typeof(this.elements[id]) === 'undefined') {
				throw('Cannot remove what doesn\'t exist');
			}

			delete this.elements[id];
		};

		ListValidation.prototype.get = function(id) {
			if (typeof(this.elements[id]) === 'undefined') {
				return undefined;
			}

			return this.elements[id];
		};

        return new ListValidation();
    }

    return {
        getInstance: function() {
            if (!instance) {
                instance = createInstance();
			}
            return instance;
        }
    };
})();

/**
 * Constructor for dynamic validation.
 * 
 * @param	HTMLElement	element		A form element to create Validation for.
 * @param	Function	initFunc	An optional function that is invoked after initialization with the Validation instance
 */
export default function Validation(element, initFunc) {
	if (!(this instanceof Validation)) {
		return new Validation(element, initFunc);
	}

	this.config = {
		errorClass: 'invalid-input',		// class indicating invalid input
		validClass:	'valid-input',			// the opposite, optional
		// if true then an empty value is valid, but an optionally provided value might still be validated
		isOptional: false,
	};

	this.data = {
		uuid: undefined,
		element: element,
		validationRule: undefined,  // either a rule from above, or 'true' to validate by input type
		updateEvent: undefined,
	};

	let getUniqueIdentifier = () => {
		let uuid;
		do {
			uuid = 'i' + Math.random().toString(36).substr(2, 10);
		} while (ListValidation.getInstance().get(uuid) != undefined);

		return uuid;
	};

	let setup = () => {
		// check for optional input
		this.config.isOptional = this.data.element.getAttribute('validate-optional') || false;

		let validate = this.data.element.getAttribute('validate');
		// Determine event to use and rule for validation
		let event, validateRule = validate;
		if (validate.split(':').length > 1) {
			let _split = validate.split(':');
			event = _split[0];
			validateRule = _split[1];
		} else {
			event = 'blur';
		}

		if (validateRule === 'true') {  // switch for trigger by type only
			this.data.validationRule = true;
		} else if (typeof(Rules[validateRule]) === 'undefined') {
			return;
		} else {  // otherwise use rule
			this.data.validationRule = Rules[validateRule];
		}

		// Setup optional event to trigger if a value update happens
		let customUpdateEvent = this.data.element.getAttribute('validate-update-event');
		if (!!customUpdateEvent) {
			// create custom event
			this.data.updateEvent = true;
		}

		// some keys are ignored by default, do not ignore all non-letter keys, like insert
		let mapIgnoreKeys = {
			'16': 1,	// shift
			'17': 1,	// ctrl
			'18': 1,	// alt
			'20': 1,	// capslock
		};
		let vig;
		if (vig = this.data.element.getAttribute('validate-ignore-keys')) {
			vig.split(',').forEach(key => mapIgnoreKeys[ key.trim() ] = 1);
		}
		let validateTab = !!this.data.element.getAttribute('validate-tab');

		event.split(' ').forEach(ev => {
			this.data.element.addEventListener(ev, e => {
				if (!!customUpdateEvent) {
					let dispEvent = new CustomEvent(customUpdateEvent, {
						detail: {
							uuid: this.data.uuid,
							value: this.getCurrentValue()
						}
					});
					window.dispatchEvent(dispEvent);
				}

				let k = e.keyCode || e.which;
				if (k === 0) {  // if no key - blur event, or any other event without key
					this.isValidInput();
				// Ignore all mapped keys, ignore tab by default, unless it is explicitely enabled
				} else if ((typeof(mapIgnoreKeys[k]) === 'undefined' && k !== 9) || (validateTab && k === 9) ) {
					this.isValidInput();
				}
			});
		});

		// autofill hack
		// https://medium.com/@brunn/detecting-autofilled-fields-in-javascript-aed598d25da7
		this.data.element.addEventListener('animationstart', ({ target, animationName }) => {
			switch (animationName) {
				case 'onAutoFillStart': break;
				default: return;  // other animations skip
			}

			if (!!customUpdateEvent) {
				let dispEvent = new CustomEvent(customUpdateEvent, {
					detail: {
						uuid: this.data.uuid,
						value: this.getCurrentValue()
					}
				});
				window.dispatchEvent(dispEvent);
			}

			this.isValidInput();
		});

		// Trigger event manually
		let triggerManually = this.data.element.getAttribute('validate-trigger');
		if (!!triggerManually) {
			window.addEventListener(triggerManually, e => this.isValidInput());
		}

		this.data.uuid = getUniqueIdentifier();
		this.data.element.setAttribute('validation', this.data.uuid);
		ListValidation.getInstance().push(this.data.uuid, this);

		if (typeof(initFunc) !== 'undefined') {
			initFunc(this);
		}
	};

	// Check if the element is already a Validation instance
	let vI = element.getAttribute('validation');
	if (!!vI) {
		return this.getInstanceById(vI);
	}

	setup();  // "private" setup
}

/**
 * Returns the current value of the form element.
 * @return	mixed	Can be string for any input, true|false for checkbox, value on radio, selected option for select.
 */
Validation.prototype.getCurrentValue = function() {
	let domEl = this.data.element;
	if (domEl.nodeName.toLowerCase() !== 'select') {
		if (domEl.nodeName.toLowerCase() !== 'input' || domEl.getAttribute('type') !== 'checkbox' && domEl.getAttribute('type') !== 'radio') {
			return domEl.value;
		} else if (domEl.getAttribute('type') === 'radio') {
			return domEl.checked ? domEl.value : undefined;
		} else {
			return domEl.checked;
		}
	} else {
		let opt = domEl.options[domEl.selectedIndex];
		return opt.value;
	}
};

/**
 * Adds or removes a `highlight-input` class to the input.
 * 
 * @param boolean	_bool	True adds hightligh, false removes highlight.
 */
Validation.prototype.toggleHighlight = function(_bool) {
	// Find the parent to add the error class to
	let parent = this.data.element.closest('.input__container');
	if (!parent) { return; }

	if (_bool === false) {
		parent.classList.remove(this.config.errorClass);
		parent.classList.add(this.config.validClass);
	} else if (_bool === true) {
		parent.classList.add(this.config.errorClass);
		parent.classList.remove(this.config.validClass);
	}
};

/**
 * Triggers the validation on the element associated with this instance of Validation.
 * Dispatches a custom "validationResult" event on the element with the validation result and current value.
 * 
 * @returns	boolean		Indicating if valid input (true), or invalid (false)
 */
Validation.prototype.isValidInput = function() {
	let valid = function(element, currentValue, rule, isOptional) {  // private
		if (currentValue === undefined || currentValue === null) {
			currentValue = '';	// removing the content from the input makes the value undef for some odd reason
		}

		if (element.required && element.required == 'true' && currentValue.length === 0) {
			return false;
		}

		// optional fields might still be validated if a value is present, if not, it is still valid
		if (isOptional && currentValue === '') {
			return true;
		}

		if (rule === true) {	// check by type
			switch (element.type) {
				case 'number':
				return currentValue.match(/\D/gi);

				case 'date':
					// We assume typical German format here: dd.mm.yyyy but we'll give some leeway
					let year, month, day;
					
					// 10-02-2018 | 10-02-18
					if (currentValue.match(/^(\d{2})\D{1}(\d{2})\D{1}(\d{4}|\d{2})/)) {
						year = parseInt(RegExp.$3, 10);
						if (year.length == 2) { year += 2000; }
						month = parseInt(RegExp.$2);
						day = parseInt(RegExp.$1, 10);
					// 2018-10-10, no abbreviated year number since 18-10-19 is ambigous if non german format
					} else if (currentValue.match(/^(\d{4}|\d{2})\D{1}(\d{2})\D{1}(\d{2})/)) {
						year = parseInt(RegExp.$1, 10);
						if (year.length == 2) { year += 2000; }
						month = parseInt(RegExp.$2);
						day = parseInt(RegExp.$3, 10);
					} else if (currentValue.match(/^(\d+)(\.|) (\D+) (\d+)/i)) {
						year = parseInt(RegExp.$4, 10);
						if (year.length == 2) { year += 2000; }
						var dict = { 'jan': 0, 'januar': 0, 'feb': 1, 'februar': 1, 'märz': 2, 'maerz': 2,
									 'april': 3, 'mai': 4, 'jun': 5, 'juni': 5, 'jul': 6, 'juli': 6,
									 'aug': 7, 'august': 7, 'sep': 8, 'september': 8, 'okt': 9, 'oktober': 9,
									 'nov': 10, 'november': 10, 'dez': 11, 'dezember': 11 }
						month = parseInt(RegExp.$3, 10);
						if (dict[month.toLowerCase()] != undefined) {
							month = dict[month.toLowerCase()];
						} else {
							month = -1;
						}
						day = parseInt(RegExp.$1, 10);
					} else {
						return false;
					}

					if (day < 0 || day > 31) {
						return false;
					} else if (month < 1 || month > 12) {
						return false;
					}

					// Let's test this native test for validity
					// https://gomakethings.com/how-to-check-if-a-date-is-valid-with-vanilla-javascript/
					/**
					 * Get the number of days in any particular month
					 * @link https://stackoverflow.com/a/1433119/1293256
					 * @param  {integer} m The month (valid: 0-11)
					 * @param  {integer} y The year
					 * @return {integer}   The number of days in the month
					 */
					var daysInMonth = function (m, y) {
						switch (m) {
							case 1 :
								return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
							case 8 : case 3 : case 5 : case 10 :
								return 30;
							default :
								return 31
						}
					};

				return month >= 0 && month < 12 && day > 0 && day <= daysInMonth(month, year);

				default:
				return true;
			}
		} else if (typeof(rule) === 'function') {
			return rule(currentValue, element);
		} else if (typeof(rule) === 'object') {
			if (rule.toString().match(/.+?object.+?/gi)) {	// JSON object with strlen obj
				if (rule.strlen !== undefined) {
					return currentValue.length === rule.strlen;
				}
			} else {  // RegExp
				return currentValue.match(rule);
			}
		}

		return false;
	};

	let isValid = true;
	let currentValue = this.getCurrentValue();
	if (!valid(this.data.element, currentValue, this.data.validationRule, this.config.isOptional)) {
		this.toggleHighlight(true);
		isValid = false;
	} else {
		this.toggleHighlight(false);
	}

	// Trigger the validation event on the element itself,
	//that way we can hook into the result from everywhere
	this.data.element.dispatchEvent(
		new CustomEvent('validationResult', {
			detail: { isValid, currentValue },
			bubbles: true, cancelable: true
		})
	);

	return isValid;
};

/**
 * Validation keeps a singleton list of all validation elements instantiated.
 * Each element gets a unique uuid as attribute. 
 * You can use this function and that id on any Validation instance to fetch the Validation instance of another element.
 * 
 * @param	string	id 	A unique identifier on an element as validation attribute.
 * @returns	Validation	A Validation instance, undefined if it doesn't exist
 */
Validation.prototype.getInstanceById = function(id) {
	return ListValidation.getInstance().get(id);
};

/**
 * Get the element this Validation instance is coupled with.
 * 
 * @returns	HTMLElement		The element associated with this instance.
 */
Validation.prototype.getElement = function() {
	return this.data.element;
};

/**
 * Adding a new "global" rule.
 * Cannot overwrite existing rules.
 * 
 * @param	string	name	The name for the new rule, must be unique.
 * @param	mixed	rule	A RegExp, function, or boolean.
 */
Validation.prototype.addRule = function(name, rule) {
	if (typeof(Rules[name]) !== 'undefined') {
		throw(`Rule '${ name }' already exists!`);
	}

	Rules[name] = rule;
};

/**
 * Get a rule by its name. Since rules have several different types, 
 * this function may return boolean, RegExp, functions or undefined for unknown rules.
 * 
 * @param	string		name	The name of the rule to be returend
 * @returns	mixed				undefined if not available, otherwise the rule itself.
 */
Validation.prototype.getRule = function(name) {
	return typeof(Rules[name]) !== 'undefined' ? Rules[name] : undefined;
};
